import socketIO from 'socket.io-client';

const API_KEY = '5d0d4189c1b39839b291da16';

export class Core {
    constructor(props) {
        this.socket = socketIO('https://cms-socket.novamedia.agency');

    }



    fetchNews(data, sort, limit, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchNews',
            query: data,
            sort: sort,
            limit: limit
        });

        this.socket.once('fetchNews', callback);
    }

    fetchNewsItem(data, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchNewsItem',
            query: data,
        });

        this.socket.once('fetchNewsItem', callback);
    }

    fetchHome(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchHome',
            query: {},
            sort: {}
        });

        this.socket.once('fetchHome', callback);
    }

    fetchAboutUs(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchAboutUs',
            query: {},
            sort: {}
        });

        this.socket.once('fetchAboutUs', callback);
    }

    fetchServices(callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchServices',
            query: {},
            sort: {}
        });

        this.socket.once('fetchServices', callback);
    }


    fetchGallery(sort, limit, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchGallery',
            query: {},
            sort: sort,
            limit: limit
        });

        this.socket.once('fetchGallery', callback);
    }
    fetchHomeGallery(sort, limit, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'fetchHomeGallery',
            query: {},
            sort: sort,
            limit: limit
        });

        this.socket.once('fetchHomeGallery', callback);
    }

    contact(data, callback) {
        this.socket.emit('apiCall', {
            apiKey: API_KEY,
            apiName: 'contact',
            query: {},
            data: data
        });

        this.socket.once('contact', callback);
    }
}