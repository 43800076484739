import React from 'react';
import { Link } from 'react-router-dom';


import Navigation from '../containers/navbar.js';
import HowWeWork from '../containers/howWeWork.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';

import onama1 from '../images/onama1.png';
import onama2 from '../images/onama2.png';
import dotsl from '../images/dotsl.png';
import dotsr from '../images/dotsr.png';
import Ilustracija from '../images/Ilustracija.png';


import {
    Col,
    Row,
    Container,
    Button,
    Breadcrumb,
    BreadcrumbItem
} from 'reactstrap';




export default class AboutUs extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        let about = this.props.aboutUs;
        const aboutLink = true;

        return (
            <>
                <div className="wrapp_nav">
                    <Navigation {...this.props} aboutLink={aboutLink}></Navigation>
                </div>

                <div className="wrapp">
                    <Container>
                        <div className="heading">
                            <h1>{this.props.translate("O NAMA")}</h1>

                        </div>
                        <div className="position">
                            <h3><Link to="/">{this.props.translate("Početna")}&nbsp;&nbsp;/&nbsp;&nbsp;</Link>{this.props.translate("O nama")}</h3>
                        </div>
                    </Container>

                </div>

                <div className="about_Section1">
                    <Container>
                        <h4>{about.subtitle && about.subtitle[this.props.lang]}</h4>

                        <h1 dangerouslySetInnerHTML={{ __html: about.title && about.title[this.props.lang] }}></h1>
                        {about.section1 && (about.section1.image[0] || about.section1.content[0]) ?
                            <div className="content">
                                <Row>
                                    <Col xl="5" lg="5" xs="12">
                                        <div className="LeftDots">
                                            <img src={about.section1.image[0]} className="img-fluid slika" />
                                            <img src={dotsl} className="img-fluid dotsl" />
                                        </div>

                                    </Col>
                                    <Col xl="7" lg="7" xs="12" dangerouslySetInnerHTML={{ __html: about.section1.content && about.section1.content[0] && about.section1.content[0][this.props.lang] }}>
                                    </Col>
                                </Row>
                            </div>
                            : null
                        }
                        {about.section1 && (about.section1.image[1] || about.section1.content[1]) ?

                            <Row>
                                <Col xl="7" lg="7" xs="12" dangerouslySetInnerHTML={{ __html: about.section1.content && about.section1.content[1] && about.section1.content[1][this.props.lang] }}>

                                </Col>
                                <Col xl="4" lg="5" xs="12" className="offset-xl-1">
                                    <div className="RightDots">
                                        <img src={about.section1.image[1]} className="img-fluid slika" />
                                        <img src={dotsr} className="img-fluid dotsr" />
                                    </div>
                                </Col>
                            </Row>
                            : null
                        }

                        {about.section1 && (about.section1.image[2] || about.section1.content[2]) ?
                            <div className="content">
                                <Row>
                                    <Col xl="5" lg="5" xs="12">
                                        <div className="LeftDots">
                                            <img src={about.section1.image[2]} className="img-fluid slika" />
                                            <img src={dotsl} className="img-fluid dotsl" />
                                        </div>

                                    </Col>
                                    <Col xl="7" lg="7" xs="12" dangerouslySetInnerHTML={{ __html: about.section1.content && about.section1.content[2] && about.section1.content[2][this.props.lang] }}>
                                    </Col>
                                </Row>
                            </div>
                            : null
                        }

                    </Container>
                </div>

                <HowWeWork {...this.props}></HowWeWork>
                <Map {...this.props}></Map>
                <Footer {...this.props}></Footer>
            </>
        );
    }
}