import React from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';
import { NavLink as RRNavLink } from 'react-router-dom';
import logo from '../images/logo.svg';
import phone from '../images/phone.svg';
import background from '../images/sub1.png';
import insta_icon from '../images/instagram.svg';
import face_icon from '../images/facebook.svg';
import bosnia_icon from '../images/bosnia.svg';
import germany_icon from '../images/germany.svg';
import serbia_icon from '../images/serbia.svg';
import english_icon from '../images/english.svg';
import nav from '../images/nav.png';
import Headroom from 'react-headroom';


import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Container,
  NavLink
} from 'reactstrap';


export default class navbar extends React.Component {
  constructor(props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.onScroll = this.onScroll.bind(this);
    this.state = {
      isTop: true
    };

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      //width: '',
      width: 0,
      height: 0

    };

  }


  onScroll(isTop) {
    this.setState({ isTop });

  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }


  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY > 1;
      if (isTop !== this.state.isTop) {
        this.onScroll(isTop);
      }

    });

  }


  render() {
    return (
      <div>
        <div className="backgroundImages">
          <img src={background} className="img-fluid background" />
          <img src={nav} className="img-fluid nav" />
        </div>
        {this.state.width <= 1024 ?
          <div className="navbarWrapper">
            <Headroom >
              <div className="wrapper">

                <div className="phone">
                  <Isvg src={phone} />
                  <p>+387 65 008 988</p>
                </div>
                {/* <div className="socialMedia">
                  <p>Pratite nas:</p>
                  <Link to="">  <Isvg src={face_icon} /></Link>
                  <Link to="">  <Isvg src={insta_icon} /></Link>
              </div>
        */}
                <div className="language d-flex">
                  <ul>
                    <li><a onClick={() => this.props.changeLanguage("sr")}><Isvg src={serbia_icon} /></a></li>
                    <li><a onClick={() => this.props.changeLanguage("ba")}><Isvg src={bosnia_icon} /></a></li>
                    <li><a onClick={() => this.props.changeLanguage("de")}><Isvg src={germany_icon} /></a></li>
                    <li><a onClick={() => this.props.changeLanguage("en")}><Isvg src={english_icon} /></a></li>

                  </ul>
                </div>
              </div>

              <Navbar className="navbar" expand="lg">
                <Container>

                  <NavbarBrand href="/"><Isvg src={logo} /> </NavbarBrand>

                  <NavbarToggler onClick={this.toggle}><i className="fa fa-bars"></i></NavbarToggler>

                  <Collapse isOpen={this.state.isOpen} navbar >

                    <div className="ml-auto">
                      <Nav navbar>

                        <NavItem>
                          <Link to={`/?lang=${this.props.lang}`} className={this.props.homeLink ? "nav-link activ" : "nav-link"}>{this.props.translate("POČETNA")}</Link>
                        </NavItem>
                        <NavItem>
                          <Link to={`/about?lang=${this.props.lang}`} className={this.props.aboutLink ? "nav-link activ" : "nav-link"}>{this.props.translate("O NAMA")}</Link>
                        </NavItem>
                        <NavItem>
                          <Link to={`/blog?lang=${this.props.lang}`} className={this.props.blogLink ? "nav-link activ" : "nav-link"}>{this.props.translate("NOVOSTI")}</Link>
                        </NavItem>
                        <NavItem>
                          <Link to={`/gallery?lang=${this.props.lang}`} className={this.props.galleryLink ? "nav-link activ" : "nav-link"}>{this.props.translate("GALERIJA")}</Link>
                        </NavItem>
                        <NavItem className="no-ma-right">
                          <Link to={`/contact?lang=${this.props.lang}`} className={this.props.contactLink ? "nav-link activ" : "nav-link"}>{this.props.translate("KONTAKT")}</Link>
                        </NavItem>
                      </Nav>
                    </div>
                  </Collapse>

                </Container>
              </Navbar>
            </Headroom>
          </div>
          :
          <div className="navbarWrapper">
            <Navbar className={!this.state.isTop ? "navbar" : "navbar down"} expand="lg">
              <Container>

                <NavbarBrand href="/"><img src={logo} className=" log" /> </NavbarBrand>

                <NavbarToggler onClick={this.toggle}><i className="fa fa-bars"></i></NavbarToggler>

                <Collapse isOpen={this.state.isOpen} navbar >

                  <div className="ml-auto">
                    <div className="wrapper">
                      <div className="phone">
                        <Isvg src={phone} />
                        <p>+387 65 008 988</p>
                      </div>
                      {/*<div className="socialMedia">
                        <p>Pratite nas:</p>
                        <Link to="#">   <Isvg src={face_icon} /></Link>
                        <Link to="#">  <Isvg src={insta_icon} /></Link>
                      </div>
        */}
                      <div className="language d-flex">
                        <ul>
                          <li><a onClick={() => this.props.changeLanguage("sr")}><Isvg src={serbia_icon} /></a></li>
                          <li><a onClick={() => this.props.changeLanguage("ba")}><Isvg src={bosnia_icon} /></a></li>
                          <li><a onClick={() => this.props.changeLanguage("de")}><Isvg src={germany_icon} /></a></li>
                          <li><a onClick={() => this.props.changeLanguage("en")}><Isvg src={english_icon} /></a></li>


                        </ul>
                      </div>
                    </div>
                    <Nav navbar>

                      <NavItem>
                        <Link to={`/?lang=${this.props.lang}`} className={this.props.homeLink ? "nav-link activ" : "nav-link"}>{this.props.translate("POČETNA")}</Link>
                      </NavItem>
                      <NavItem>
                        <Link to={`/about?lang=${this.props.lang}`} className={this.props.aboutLink ? "nav-link activ" : "nav-link"}>{this.props.translate("O NAMA")}</Link>
                      </NavItem>
                      <NavItem>
                        <Link to={`/blog?lang=${this.props.lang}`} className={this.props.blogLink ? "nav-link activ" : "nav-link"}>{this.props.translate("NOVOSTI")}</Link>
                      </NavItem>
                      <NavItem>
                        <Link to={`/gallery?lang=${this.props.lang}`} className={this.props.galleryLink ? "nav-link activ" : "nav-link"}>{this.props.translate("GALERIJA")}</Link>
                      </NavItem>
                      <NavItem className="no-ma-right">
                        <Link to={`/contact?lang=${this.props.lang}`} className={this.props.contactLink ? "nav-link activ" : "nav-link"}>{this.props.translate("KONTAKT")}</Link>
                      </NavItem>

                    </Nav>
                  </div>
                </Collapse>

              </Container>
            </Navbar>
          </div>
        }
      </div>
    );
  }
}