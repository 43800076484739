import React from 'react';
import { Link } from 'react-router-dom';

import Navigation from '../containers/navbar.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import NewsComponent from '../containers/newsComponent.js';

import slider1 from '../images/slider.png';
import slider2 from '../images/slider2.png';
import news from '../images/news.png';
import moment from 'moment';

import {
    Container,
    Col,
    Row
} from 'reactstrap';
const articles = [
    {
        _id: '1',
        allias: 'sdsd',
        title: 'Naslov prve vijesti',
        fullDate: '18.06.2019. god.  |  21:45 h',
        date: '24',
        month: 'Maj 2019',
        description: 'Kao Vaš pouzdan partner, PRO VIA  je uvijek spremna da odgovori na sve Vaše zahtjeve.',
        image: slider1,
        link: '/singleNews',
        line: '<hr/>'
    },
    {
        _id: '2',
        allias: 'sdsd',
        title: 'Naslov prve vijesti',
        fullDate: '18.06.2019. god.  |  21:45 h',
        date: '24',
        month: 'Maj 2019',
        description: 'Kao Vaš pouzdan partner, PRO VIA  je uvijek spremna da odgovori na sve Vaše zahtjeve.',
        image: slider2,
        link: '/test'
    },
    {
        _id: '3',
        allias: 'sdsd',
        title: 'Naslov prve vijesti 3',
        fullDate: '18.06.2019. god.  |  21:45 h',
        date: '24',
        month: 'Maj 2019',
        description: 'Kao Vaš pouzdan partner, PRO VIA  je uvijek spremna da odgovori na sve Vaše zahtjeve.',
        image: slider1,
        link: '/test'
    }, {
        _id: '4',
        allias: 'sdsd',
        title: 'Naslov prve vijesti 4',
        fullDate: '18.06.2019. god.  |  21:45 h',
        date: '24',
        month: 'Maj 2019',
        description: 'Kao Vaš pouzdan partner, PRO VIA  je uvijek spremna da odgovori na sve Vaše zahtjeve.',
        image: slider2,
        link: '/test'
    }
];
export default class singleNews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            news: []
        };
    }
    componentDidUpdate(prevProps) {
        window.scrollTo(0, 0);

        if (prevProps[0].location.pathname !== this.props[0].location.pathname) {
            this.props.core.fetchNewsItem({ _id: this.props[0].match.params.id }, (data) => {
                this.setState(data);
            })

            this.props.core.fetchNews({}, { time: -1 }, 2, (data) => {
                this.setState({ news: data.result })
            })


        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);

        this.props.core.fetchNewsItem({ _id: this.props[0].match.params.id }, (data) => {
            this.setState(data);
        })

        this.props.core.fetchNews({}, { time: -1 }, 2, (data) => {
            this.setState({ news: data.result })
        })


    }


    render() {
        const singleNews = 'singleNews';
        return (
            <>
                <div className="wrapp_nav">
                    <Navigation {...this.props}></Navigation>
                </div>

                <div className="wrapp">
                    <Container>
                        <div className="heading">
                            <h1>{this.props.translate("NOVOSTI")}</h1>

                        </div>
                        <div className="position">
                            <h3><Link to="/">{this.props.translate("Početna")}&nbsp;&nbsp;/&nbsp;&nbsp;</Link>{this.props.translate("Novosti")}</h3>
                        </div>
                    </Container>


                </div>
                <div className="about_Section1">
                    <Container>
                        <Row>
                            <Col xl="8" md="12" lg="12">
                                <h3><span>{this.state.title && this.state.title[this.props.lang]}</span></h3>
                            </Col>
                            <Col xl="4">
                                <h2>{this.props.translate("Najnovije")}</h2>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="singlePage">
                    <Container>

                        <Row >
                            <Col xl="8">
                                <div className="headText">
                                    <h3>{moment.unix(this.state.time).format('DD.MM.YYYY.')} god.  |  {moment.unix(this.state.time).format('HH:mm')} h</h3>
                                    <p>{this.state.shortDescription && this.state.shortDescription[this.props.lang]}</p>
                                </div>
                                <img src={this.state.image} className="img-fluid detailImage" />
                                <br></br>
                                <br></br>


                                <div dangerouslySetInnerHTML={{ __html: this.state.content && this.state.content[this.props.lang] }}></div>
                            </Col>
                            <Col xl="4" >

                                <div className="wrapp_news">

                                    <NewsComponent translate={this.props.translate} lang={this.props.lang} singleNews={singleNews} items={this.state.news}></NewsComponent>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </div>

                <Map {...this.props}></Map>

                <Footer {...this.props}></Footer>
            </>
        );
    }
}