import React from 'react';
import { Link } from 'react-router-dom';

import Navigation from '../containers/navbar.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';

import slika1 from '../images/gallery/slika1.png';
import slika2 from '../images/gallery/slika2.png';
import slika3 from '../images/gallery/slika66.png';
import slika6 from '../images/gallery/slika6.png';
import lupa from '../images/lupa.png';

import {
    Col,
    Row,
    Container,
    Button,
    Carousel,
    CarouselIndicators,
    CarouselItem
} from 'reactstrap';
const pictures = [
    {
        src: slika1,
        altText: 'Picture1',
        caption: 'Picture1'
    },
    {
        src: slika2,
        altText: 'Picture2',
        caption: 'Picture2'
    },
    {
        src: slika3,
        altText: 'Picture3',
        caption: 'Picture3'
    },
    {
        src: slika6,
        altText: 'Picture3',
        caption: 'Picture3'
    }
];
export default class Gallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
        };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.props.gallery.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.props.gallery.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }


    render() {
        const activeIndex = this.state.activeIndex;
        const slides = this.props.gallery.map((item) => {
            return (
                <CarouselItem className="bg"
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item}
                >
                    <img src={item} className="img-fluid" />
       
                </CarouselItem>
            )
        });
        const galleryLink = true;
        return (
            <>
                <div className="wrapp_nav">
                <Navigation {...this.props} galleryLink = {galleryLink}></Navigation>
                </div>

                <div className="wrapp">
                    <Container>
                        <div className="heading">
                            <h1>{this.props.translate("GALERIJA")}</h1>

                        </div>
                        <div className="position">
                            <h3><Link to="/">{this.props.translate("Početna")}&nbsp;&nbsp;/&nbsp;&nbsp;</Link>{this.props.translate("Galerija")}</h3>
                        </div>
                    </Container>
                </div>
                
                <div className="about_Section1">
                    <Container>
                    <h1><span>{this.props.translate("Naši")}</span> {this.props.translate("radovi")}</h1>
                   <p>{this.props.translate("Pogledajte neke od naših radova")}</p>

                        <Row>
                            {
                                this.props.gallery.map((item, idx) => {
                                    return (
                                        <Col xl={3} lg={3} md={4} sm={12} key={idx}>
                                        <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: idx })} >
                                            <img src={item} className="img-fluid slika" />
                                            <div className="overlay">
                                                <img src={lupa}/>
                                                </div>
                                           
                                        </div>
                                    </Col>
        
                                    )
                                })
                            }

                            {this.state.opened ?
                                <div className="lightbox">
                                    <Container>
                                        <Row>
                                            <i className="fa fa-times" aria-hidden="true" onClick={() => this.setState({ opened: false })}></i>

                                            <Carousel className="bg"
                                                activeIndex={activeIndex}
                                                next={this.next}
                                                previous={this.previous}
                                            >
                                                <CarouselIndicators items={pictures} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                                                {slides}
                                                <i className="fa fa-angle-left" onClick={this.previous}></i>
                                                <i className="fa fa-angle-right" onClick={this.next}></i>
                                            </Carousel>
                                        </Row>
                                    </Container>
                                </div> : null
                            }
                        </Row>
                    </Container>
                </div>





                <Map {...this.props}></Map>
                <Footer {...this.props}></Footer>
            </>
        );
    }
}