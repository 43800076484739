import React from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';

import Navigation from '../containers/navbar.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import News from '../containers/newsComponent.js';

import ReactPaginate from 'react-paginate';

import slider1 from '../images/slider.png';
import slider2 from '../images/slider2.png';
import {
    Container,
    Col,
    Row
} from 'reactstrap';
const articles = [
    {
        _id: '1',
        allias: 'sdsd',
        title: 'Naslov prve vijesti 1',
        date: '24',
        month: 'Maj 2019',
        description: 'Kao Vaš pouzdan partner, PRO VIA  je uvijek spremna da odgovori na sve Vaše zahtjeve.',
        image: slider1,
        link: '/singleNews'
    },
    {
        _id: '2',
        allias: 'sdsd',
        title: 'Naslov prve vijesti 2',
        date: '24',
        month: 'Maj 2019',
        description: 'Kao Vaš pouzdan partner, PRO VIA  je uvijek spremna da odgovori na sve Vaše zahtjeve.',
        image: slider2,
        link: '/singleNews'
    },
    {
        _id: '3',
        allias: 'sdsd',
        title: 'Naslov prve vijesti 3',
        date: '24',
        month: 'Maj 2019',
        description: 'Kao Vaš pouzdan partner, PRO VIA  je uvijek spremna da odgovori na sve Vaše zahtjeve.',
        image: slider1,
        link: '/singleNews'
    },
    {
        _id: '4',
        allias: 'sdsd',
        title: 'Naslov prve vijesti 4',
        date: '24',
        month: 'Maj 2019',
        description: 'Kao Vaš pouzdan partner, PRO VIA  je uvijek spremna da odgovori na sve Vaše zahtjeve.',
        image: slider2,
        link: '/singleNews'
    },
    {
        _id: '5',
        allias: 'sdsd',
        title: 'Naslov prve vijesti 4',
        date: '24',
        month: 'Maj 2019',
        description: 'Kao Vaš pouzdan partner, PRO VIA  je uvijek spremna da odgovori na sve Vaše zahtjeve.',
        image: slider2,
        link: '/singleNews'
    },
    {
        _id: '6',
        allias: 'sdsd',
        title: 'Naslov prve vijesti 4',
        date: '24',
        month: 'Maj 2019',
        description: 'Kao Vaš pouzdan partner, PRO VIA  je uvijek spremna da odgovori na sve Vaše zahtjeve.',
        image: slider2,
        link: '/singleNews'
    }
];
export default class NewsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            news: []
        }

    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.core.fetchNews({}, { time: -1 }, null, (data) => {
            console.log(data.result);


            this.setState({ news: data.result })
        })

    }

    render() {
        const settings = {

            previousLabel: '',
            previousLinkClassName: 'no-ma-left',
            nextLabel: '',
            breakLabel: '...',
            breakClassName: 'break-me',
            pageCount: 6,
            marginPagesDisplayed: 1,
            pageRangeDisplayed: 2,
            pageClassName: 'svi',
            pageLinkClassName: 'allLink',
            containerClassName: 'pagination',
            subContainerClassName: 'pages pagination',
            activeClassName: 'active',

        }
        const blogLink = true;
        return (
            <>
                <div className="wrapp_nav">
                    <Navigation {...this.props} blogLink={blogLink}></Navigation>
                </div>

                <div className="wrapp">
                    <Container>
                        <div className="heading">
                            <h1>{this.props.translate("NOVOSTI")}</h1>

                        </div>
                        <div className="position">
                            <h3><Link to={`/?lang=${this.props.lang}`}>{this.props.translate("Početna")}&nbsp;&nbsp;/&nbsp;&nbsp;</Link>{this.props.translate("Novosti")}</h3>
                        </div>
                    </Container>

                </div>
                <div className="about_Section1">
                    <Container>

                        <p>{this.props.translate("Aktuelnosti vezane za našu firmu")}</p>
                    </Container>
                </div>

                <News translate = {this.props.translate} items={this.state.news} lang={this.props.lang}></News>

                <Container>
                    {/*<ReactPaginate {...settings} />*/}
                </Container>


                <Map {...this.props}></Map>
                <Footer {...this.props}></Footer>
            </>
        );
    }
}

