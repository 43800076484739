import React from 'react';
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';

import Navigation from '../containers/navbar.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer';

import phone_icon from '../images/phone_icon.svg';
import email_icon from '../images/email.svg';
import fax_icon from '../images/telephone.svg';


import {
    Col, Container, Button, Row, Input, Label, FormGroup, NavItem, NavLink
} from 'reactstrap';


export default class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            subject: '',
            message: '',

        }
        this.onChange = this.onChange.bind(this);
        this.contact = this.contact.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    contact(e) {
        e.preventDefault();
        if(!this.state.email || !this.state.message) {
            this.setState({
                errors: true
            })
            return
        }
        this.props.core.contact({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email, phone: this.state.phone, subject: this.state.subject,
            message: this.state.message
        }, (data) => {
            this.setState({
                _done: true,
                errors: null
            })
        })

      
    }
    onChange(e) {

        let obj = {};
        obj[e.target.name] = e.target.value;
        this.setState(obj);
    }

    render() {
        const contactLink = true;
        return (
            <>
                <div className="wrapp_nav">
                    <Navigation {...this.props} contactLink={contactLink}></Navigation>
                </div>

                <div className="wrapp">
                    <Container>
                        <div className="heading">
                            <h1>{this.props.translate("KONTAKT")}</h1>

                        </div>
                        <div className="position">
                            <h3><Link to="/">{this.props.translate("Početna")}&nbsp;&nbsp;/&nbsp;&nbsp;</Link>{this.props.translate("Kontakt")}</h3>
                        </div>
                    </Container>

                </div>
                <div className="about_Section1">
                    <Container>
                        <h1><span>{this.props.translate("Kontaktirajte")}</span> {this.props.translate("nas")}</h1>
                        <p>{this.props.translate("Budite slobodni da nas kontaktirate. Odgovorićemo Vam u što kraćem roku.")}</p>
                    </Container>
                </div>
                <div className="contact">
                    <Container>

                        <Row>
                            <Col xs="12" xl="8" md="7" lg="7">
                                <form onSubmit={this.contact}>
                                    <Row>
                                        <Col xl="6" md="6" lg="6" xs="12" sm="12">
                                            <FormGroup>
                                                <Input type="text" name="firstName" placeholder="Ime" onChange={this.onChange} value={this.state.firstName} />
                                                <Label for="ime">{this.props.translate("VAŠE IME")}</Label>
                                            </FormGroup>
                                        </Col>
                                        <Col xl="6" md="6" lg="6" xs="12" sm="12">
                                            <FormGroup>
                                                <Input type="text" name="lastName" id="" placeholder="Prezime" onChange={this.onChange} value={this.state.lastName} />
                                                <Label for="prezime">{this.props.translate("VAŠE PREZIME")}</Label>
                                            </FormGroup>

                                        </Col>
                                        <Col xl="6" md="6" lg="6" xs="12" sm="12">
                                            <FormGroup>
                                                <Input type="text" name="phone" id="" placeholder="Telefon" onChange={this.onChange} value={this.state.phone} />
                                                <Label for="phone">{this.props.translate("VAŠ BROJ TELEFONA")}</Label>
                                            </FormGroup>
                                        </Col>
                                        <Col xl="6" md="6" lg="6" xs="12" sm="12">
                                            <FormGroup>
                                                <Input type="text" name="email" id="" placeholder="* E-mail" onChange={this.onChange} value={this.state.email} />
                                                <Label for="telefon">{this.props.translate("VAŠA E-MAIL ADRESA")}</Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <FormGroup>
                                        <Input type="text" name="subject" id="naslov" placeholder="Naslov" onChange={this.onChange} value={this.state.subject} />
                                        <Label for="naslov">{this.props.translate("SUBJECT")}</Label>
                                    </FormGroup>
                                    <FormGroup>
                                        <Input type="textarea" name="message" id="textarea" placeholder="* Vaša poruka" onChange={this.onChange} value={this.state.message} />
                                        <Label for="textarea">{this.props.translate("PORUKA")}</Label>
                                    </FormGroup>
                                    <Button>{this.props.translate("POŠALJI")}</Button>
                                    {this.state._done ?
                                        <div className="alert alert-success mt-3" role="alert">
                                            {this.props.translate("Vaša poruka je uspješno poslata, očekujte odgovor ubrzo!")}
                                        </div>
                                        : null
                                    }
                                    {this.state.errors ?
                                        <div className="alert alert-danger mt-3" role="alert">
                                            {this.props.translate("Popunite obavezna polja!")}
                                        </div>
                                        : null
                                    }

                                </form>
                            </Col>
                            <Col xl="4" xs="12" md="5" lg="5" sm="12">
                                <div className="contancInfo">
                                    <div className="content">
                                        <h2>{this.props.translate("INFORMACIJE")}</h2>

                                        <h3><span>{this.props.translate("PRO VIA d.o.o.")}</span><br />
                                            {this.props.translate("Stefana Dečanskog")}<br />
                                            {this.props.translate("ATC, lamela B/15")}</h3>
                                        <br />
                                        <h3> {this.props.translate("76300 Bijeljina")}<br />
                                            {this.props.translate("Republika Srpska, BiH")}</h3>

                                        <div className="contantDetail">
                                            <h3><Isvg src={phone_icon} /> {this.props.translate("+387 65 008 988")}</h3>
                                            <h3 className="fax"><Isvg src={fax_icon} />{this.props.translate("+387 55 240 360")}</h3>
                                            <h3><Isvg src={email_icon} className="emailSVG" /> {this.props.translate("proviadoo@gmail.com")}</h3>
                                        </div>

                                        <h4>{this.props.translate("RADNO VRIJEME")}</h4>

                                        <p><span>{this.props.translate("Ponedeljak - Petak")}</span></p>
                                        <p>{this.props.translate("07:00 - 15:00")}</p>


                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Map {...this.props}></Map>
                <Footer {...this.props}></Footer>
            </>
        );
    }
}