import React from 'react';
import {Link} from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import slider1 from '../images/slider.png';
import moment from 'moment';

import {
    Container,
    Col,
    Row,
    Button
} from 'reactstrap';

export default class NewsItem extends React.Component{
    constructor(props){
        super(props);
    }
    render(){

        return(
            <>
        
             
            <div className="wrapp-slider">
                          <div className="slide">
                          <Link to={`/blog/${this.props._id}/${this.props.alias}?lang=${this.props.lang}`}>  <img src={this.props.image} className="img-fluid" /></Link>
                                    <div className="content">
                                     
                                        <div className="date">
                                        <h2>{moment.unix(this.props.time).format('DD')}</h2>
                                            <p>{moment.unix(this.props.time).format('MMM')}</p>
                                        </div>
                                        <div className="text">
                                        <Link to={`/blog/${this.props._id}/${this.props.alias}`}>    <h4>{this.props.title && this.props.title[this.props.lang]}</h4></Link>
                                            <h3>{moment.unix(this.props.time).format('DD.MM.YYYY.')} god.  |  {moment.unix(this.props.time).format('HH:mm')} h</h3>
                                            <div className="dsa">
                                            <p>{this.props.shortDescription && this.props.shortDescription[this.props.lang]}</p>
                                            </div>
                                            <Link to={`/blog/${this.props._id}/${this.props.alias}`}> <h6><Button ></Button>{this.props.translate("Detaljnije")}</h6></Link>
                                         
                                        </div>
                                    </div>
                                
                                </div>
                                <p dangerouslySetInnerHTML={{__html:this.props.line}}></p>
                                </div>
                            
                            
                            
            </>
        );
    }
}