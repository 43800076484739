import React, { Component } from 'react';
import Slider from "react-slick";


import SliderItem from './sliderItem';
import slider1 from '../images/slider.png';
import slider2 from '../images/slider2.png';

import {
    Container,
    Row,
    Col
} from 'reactstrap';

const articles = [
    {
        _id: '1',
        allias: 'sdsd',
        title: 'Naslov prve vijesti',
        fullDate: '18.06.2019. god.  |  21:45 h',
        date: '24',
        month: 'Maj 2019',
        description: 'Kao Vaš pouzdan partner, PRO VIA  je uvijek spremna da odgovori na sve Vaše zahtjeve.',
        image: slider1,
        link: '/singleNews',
        line: '<hr/>'
    },
    {
        _id: '2',
        allias: 'sdsd',
        title: 'Naslov prve vijesti',
        fullDate: '18.06.2019. god.  |  21:45 h',
        date: '24',
        month: 'Maj 2019',
        description: 'Kao Vaš pouzdan partner, PRO VIA  je uvijek spremna da odgovori na sve Vaše zahtjeve.',
        image: slider2,
        link: '/singleNews'
    },
    {
        _id: '3',
        allias: 'sdsd',
        title: 'Naslov prve vijesti 3',
        fullDate: '18.06.2019. god.  |  21:45 h',
        date: '24',
        month: 'Maj 2019',
        description: 'Kao Vaš pouzdan partner, PRO VIA  je uvijek spremna da odgovori na sve Vaše zahtjeve.',
        image: slider1,
        link: '/singleNews'
    }, {
        _id: '4',
        allias: 'sdsd',
        title: 'Naslov prve vijesti 4',
        fullDate: '18.06.2019. god.  |  21:45 h',
        date: '24',
        month: 'Maj 2019',
        description: 'Kao Vaš pouzdan partner, PRO VIA  je uvijek spremna da odgovori na sve Vaše zahtjeve.',
        image: slider2,
        link: '/singleNews'
    }
];

export default class SliderComponent extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        var settings = {
            dots: false,
            //   infinite: true,
            arrows: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            adaptiveHeight: true,
            //    autoplay: true,
            responsive:
                [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]

        };
        console.log(this.props.homeNews);
        return (
            <>
                <Slider {...settings} >
                    {this.props.homeNews.map((item) => {
                        return (
                            <div key={item._id}>
                            <SliderItem translate={this.props.translate} lang={this.props.lang} {...item}></SliderItem>
                            </div>
                        )
                    })}
                </Slider>

            </>
        );
    }
}