import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Isvg from 'react-inlinesvg';
import f from '../images/footer.png';
import logoFooter from '../images/logoFooter.svg';
import face_icon from '../images/facebook.svg';
import insta_icon from '../images/instagram.svg';
import phone from '../images/phoneRed.svg';
import time from '../images/time.svg';




import {
    Col,
    Row,
    Container,
    Button,
    NavItem

} from 'reactstrap';


export default class Footer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="footer">
                    <Container>
                <div className="d-flex justify-content-end ptag">
                                    <p>{this.props.translate("Pratite nas:")} <Isvg src={face_icon}/><Isvg src={insta_icon}/></p>
                                    </div>
                                    </Container>
                <div className="redSection"></div>
            
                <div className="content">
                        <Container>
                   
                            <Row>
                                <Col xl="6" lg="6" md="6">
                                <Isvg src={logoFooter} className="logoFooter" />
                                <p>{this.props.translate("PRO VIA d.o.o. Bijeljina osnovano je")}<br></br>
                                {this.props.translate("2008. godine sa osnovnom djelatnošću ")}<br></br>
                                {this.props.translate("proizvodnja, ugradnja i promet saobraćajne")}<br></br>
                                {this.props.translate("signalizacije i opreme puteva.")}</p>
                                </Col>
                                <Col xl="6" lg="6" md="6">
                                  <ul className="no-pa">
                                      <li><Link to = {`/?lang=${this.props.lang}`}>{this.props.translate("POČETNA")}</Link></li>
                                      <li><Link to = {`/about?lang=${this.props.lang}`}>{this.props.translate("O NAMA")}</Link></li>
                                      <li><Link to = {`/blog?lang=${this.props.lang}`}>{this.props.translate("NOVOSTI")}</Link></li>
                                      <li><Link to = {`/gallery?lang=${this.props.lang}`}>{this.props.translate("GALERIJA")}</Link></li>
                                      <li className="no-ma-right"><Link to = {`/contact?lang=${this.props.lang}`} className = "no-ma-right">{this.props.translate("KONTAKT")}</Link></li>
                                  </ul>
                                  <Row className="no-gutters" style={{clear: 'both'}}>
                                    <Col xl="6" lg="6" xs="12" className="offset-xl-1" md="5">
                                        <div className="info">
                                        
                                        <p><Isvg src={phone} />{this.props.translate("Telefon:")}
                                            <br></br>
                                            <span>+387 65 008 988</span>

                                        </p>
                                        </div>
                                    </Col>
                                    <Col xl="5" lg="6" xs="12" md="7">
                                        <div className="info">
                                        
                                        <p><Isvg src={time} />{this.props.translate("Radno vrijeme:")}<br></br>
                                            <span>{this.props.translate("PON - PET ")}<br></br> {this.props.translate("07:00 - 15:00h")}</span>
                                        </p>
                                        </div>
                                    </Col>

                                  </Row>
                                </Col>

                            </Row>
                      

                        </Container>
                    </div> 
               <div className="copyright">
                    <Container>
                        <Row>
                            <Col xl="6" lg="6" md="8" sm="12" xs="12">
                                <p>{this.props.translate("Copyright © PRO VIA d.o.o.  - 2019. All Rights Reserved.")}</p>
                            </Col>
                            <Col xl="3" lg="3" md="4" sm="12" xs="12" className="offset-xl-3 offset-lg-3 d-flex justify-content-xl-end justify-content-sm-start nova">
                                <p>{this.props.translate("Created by")} <span><a href="https://www.novamedia.agency/" target="_blank">NOVA media</a></span></p>
                            </Col>
                        </Row>
                    </Container>
                    </div>
                </div>
          
            </div>
        );
    }
}