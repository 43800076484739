import React from 'react';
import Isvg from 'react-inlinesvg';
import { Link } from 'react-router-dom';

import Navigation from '../containers/navbar.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';

import vertikalna from '../images/ourservice/vertiaklnaSignalizacija.svg';
import horizontalna from '../images/ourservice/horizontalnaSignalizacija.svg';
import saobracajnaO from '../images/ourservice/saobracajnaOprema.svg';
import zastitnaO from '../images/ourservice/zastitnaOgrada.svg';
import projektovanje from '../images/ourservice/projektovanje.svg';

import znak from '../images/ourservice/znak.png';
import dots from '../images/ourservice/dots.png';
import radovi from '../images/ourservice/radovinaputu.svg';


import {
    Container,
    Row,
    Col
} from 'reactstrap';

export default class ourServices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: 0
        }
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        for (let i = 0; i < this.props.services.length; i++) {
            if (this.props[0].match.params.alias == this.props.services[i].alias) {
                this.setState({ item: i })
            }
        }
    }

    render() {

        let data = {};
        if (this.props.services && this.props.services[this.state.item]) {
            data = this.props.services[this.state.item];
        }


        return (
            <>
                <div className="wrapp_nav">
                <Navigation {...this.props}></Navigation>
                </div>

                <div className="wrapp">
                    <Container>
                        <div className="heading">
                            <h1>{this.props.translate("NAŠE USLUGE")}</h1>

                        </div>
                        <div className="position">
                            <h3><Link to="/">{this.props.translate("Početna")}&nbsp;&nbsp;/&nbsp;&nbsp;</Link>{this.props.translate("Usluge")}</h3>
                        </div>


                    </Container>

                </div>


                <div className="ourServices">
                    <Container>
                        <Row>
                            <Col xl="4" lg="4" md="5" xs="12">

                                {
                                    this.props.services.map((item, idx) => {
                                        return (


                                            <div className={idx == this.state.item ? 'box activ' : 'box'} onClick={() => { this.setState({ item: idx }) }}>
                                                <div className="content">
                                                    <img src={item.icon} />
                                                    <div className="heding">
                                                        <h3>{item.name && item.name[this.props.lang]}</h3>
                                                    </div>
                                                </div>
                                            </div>


                                        )
                                    })
                                }
                            </Col>
                            <Col xl="8" lg="8" md="7" xs="12">
                                <h1><span>{data.name && data.name[this.props.lang]}</span></h1>
                                {this.props.services[this.state.item] && this.props.services[this.state.item].inProgress ? 
                                    <>
                                        <div className="radovi"><Isvg src={radovi} /></div>
                                        <h2 style={{ textAlign: 'center' }}>{this.props.translate("Radovi na izradi stranice u toku.")}</h2>
                                    </>
                                    : null
                                   }
                                {
                                    data.sections && data.sections.map((item, idx) => {
                                        if (item.content && !item.image) {
                                            return (
                                                <div dangerouslySetInnerHTML={{ __html: item.content[this.props.lang] }}>
                                                </div>


                                            )
                                        } else if (item.content && item.image) {
                                            return (
                                                <Row className="no-gutters">
                                                    <Col xl="8" md="8" lg="8" dangerouslySetInnerHTML={{ __html: item.content[this.props.lang] }}>

                                                    </Col>
                                                    <Col xl="4" md="4" lg="4">
                                                        <div className="dot">
                                                            <img src={item.image} className="img-fluid" />
                                                            <img src={dots} className="img-fluid dots" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )

                                        }
                                    })
                                }

                            </Col>
                        </Row>
                    </Container>
                </div>



                <Map {...this.props}></Map>
                <Footer {...this.props}></Footer>
            </>
        );
    }
}