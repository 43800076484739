import React from 'react';
import NewsItem from './newsItem.js';




import {
    Container,
     Row, 
     Col
} from 'reactstrap';





export default class NewsComponent extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        const toSingle = this.props.singleNews;
        return(
            <>
              <Container>
              
              <Row>
                    {this.props.items.map((item) =>
                        <Col xl={this.props.singleNews ? "12" : "6"} xs="12" lg="6" md="6" className={this.props.singleNews ? "no-pa" : ""}>
                         
                            <NewsItem translate = {this.props.translate} lang={this.props.lang} {...item}></NewsItem>
                           
                        </Col>
                        )}
             
             </Row>
            </Container>
            
            </>

        );
    }
}

