import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import langs from './langs';
import Home from './views/home.js';
import About from './views/about.js';
import Gallery from './views/gallery.js';
import Contact from './views/contact.js';
import NewsPage from './views/newsPage.js';
import SingleNews from './views/singleNews.js';
import OurServices from './views/ourServices.js';
import { Core } from './core';

import './App.css';
import GoogleMap from './googleMapScript.js';
class App extends React.Component {
  constructor(props) {
    super(props);
    this.callback = this.callback.bind(this);
    this.translate = this.translate.bind(this);
    window.googleMapsCallback = this.callback;
    this.changeLanguage = this.changeLanguage.bind(this);

    this.core = new Core();

    this.state = {
      lang: 'ba',
      products: [],
      homeNews: [],
      gallery: [],
      homeGallery: [],
      home: {},
      aboutUs: {},
      services: []

    };
  }
  callback() {
    this.setState({ mapinit: true });
  }


  changeLanguage(lang){
    this.setState({
      lang: lang
    })
  }

  componentDidMount() {

    this.core.fetchHome((data) => {
      this.setState({ home: data })
    })

    this.core.fetchServices((data) => {
      this.setState({ services: data })
    })


    this.core.fetchAboutUs((data) => {
      console.log(data);
      this.setState({ aboutUs: data })
    })


    this.core.fetchNews({}, { time: -1 }, 3, (data) => {
      console.log(data.result);

      if (data.result.length <= 2 && data.result.length !== 0) {
        let idx = 0;
        while (data.result.length < 4) {
          data.result.push(data.result[idx]);
            idx++;
        }
    }


      this.setState({ homeNews: data.result })
    })


    this.core.fetchHomeGallery({position: -1}, 8, (data) => {
      this.setState({ homeGallery: data })
    })
    this.core.fetchGallery({position: -1}, null, (data) => {
      this.setState({ gallery: data })
    })


    if (window.location.href.indexOf('?lang=') !== -1){
      let lang = window.location.href.split('?lang=')[1];
      this.setState({
        lang: lang
      })

    }


  }

  translate(text){

   /* if (!localStorage.translate){
       localStorage.translate = JSON.stringify({
        'de': {
 
        },'en': {
 
         },'ba' : {

         },'sr' : {

        }
         
       });
     }
 
     let obj = JSON.parse(localStorage.translate);
     obj.ba[text] = text;
     obj.en[text] = text;
     obj.de[text] = text;
 
     localStorage.translate = JSON.stringify(obj);
     
     return text;
 */
     if (langs[this.state.lang][text]) {
       return langs[this.state.lang][text];
     } else {
       return text
     }
     
 
  }

  render() {
    return (
      <div>
         <GoogleMap API_KEY="AIzaSyAAqbIo7N0_Rpwtay3-CWzo5gkfpgWZ4to" />
        <Router>
          <Switch>
          <Route exact path="/" render={(...renderProps) => (<Home {...renderProps} {...this.state} changeLanguage={this.changeLanguage}  translate={this.translate}core={this.core}  ></Home>)} />
          <Route exact path="/section1" render={(...renderProps) => (<Home {...renderProps} {...this.state} changeLanguage={this.changeLanguage}  translate={this.translate}core={this.core}  ></Home>)} />
          <Route exact path="/about" render={(...renderProps) => (<About {...renderProps} {...this.state} changeLanguage={this.changeLanguage}  translate={this.translate}core={this.core} ></About>)}/>
          <Route exact path="/gallery" render={(...renderProps) => (<Gallery {...renderProps} {...this.state} changeLanguage={this.changeLanguage}  translate={this.translate}core={this.core} ></Gallery>)}/>
          <Route exact path="/contact" render={(...renderProps) => (<Contact {...renderProps} {...this.state} changeLanguage={this.changeLanguage}  translate={this.translate}core={this.core} ></Contact>)}/>
          <Route exact path="/blog" render={(...renderProps) => (<NewsPage {...renderProps} {...this.state} changeLanguage={this.changeLanguage}  translate={this.translate}core={this.core} ></NewsPage>)}/>
          <Route exact path="/blog/:id/:alias" render={(...renderProps) => (<SingleNews {...renderProps} {...this.state} changeLanguage={this.changeLanguage}  translate={this.translate}core={this.core} ></SingleNews>)}/>
          <Route exact path="/ourServices" render={(...renderProps) => (<OurServices {...renderProps}{...this.state} changeLanguage={this.changeLanguage}  translate={this.translate}core={this.core} ></OurServices>)}/>
          <Route exact path="/ourServices/:alias" render={(...renderProps) => (<OurServices {...renderProps}{...this.state} changeLanguage={this.changeLanguage}  translate={this.translate}core={this.core} ></OurServices>)}/>

          </Switch>
        </Router>
      </div>
    )
  }
}

export default App;
